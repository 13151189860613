module.exports = [{
      plugin: require('/Users/joeloliveira/Development/WWW/Projects/sitelabel/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":590},
    },{
      plugin: require('/Users/joeloliveira/Development/WWW/Projects/sitelabel/node_modules/gatsby-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/joeloliveira/Development/WWW/Projects/sitelabel/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/joeloliveira/Development/WWW/Projects/sitelabel/src/intl","languages":["en","nl","pt"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('/Users/joeloliveira/Development/WWW/Projects/sitelabel/node_modules/gatsby-plugin-lunr/gatsby-browser.js'),
      options: {"plugins":[],"languages":[{"name":"en"},{"name":"nl"},{"name":"pt"}],"fields":[{"name":"title","store":true,"attributes":{"boost":20}},{"name":"content"},{"name":"url","store":true}],"resolvers":{"MarkdownRemark":{}}},
    },{
      plugin: require('/Users/joeloliveira/Development/WWW/Projects/sitelabel/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
